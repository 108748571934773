<template lang="pug">
b-card
  b-form
    b-row
      // Name
      b-col(md="12")
        b-form-group(
          :label="$t('label.Name')",
          label-for="name",
          description=""
          )
          b-form-input(
            id="name"
            v-model="data.username"
            :placeholder="$t('placeholder.name')"
            )

    b-row
      // Email
      b-col(md="12")
        b-form-group(
          :label="$t('label.Email')",
          label-for="email",
          description=""
          )
          b-form-input(
            id="email"
            v-model="data.email"
            :disabled="data.email ? true : false"
            :placeholder="$t('placeholder.email')"
            )

    b-row
      // Project
      b-col(md="12")
        b-form-group(
          :label="$t('label.project')",
          label-for="project",
          description=""
          )
          b-form-input(
            id="project"
            v-model="data.project"
            :placeholder="$t('placeholder.project')"
            )
    
    b-row
      b-col(
        cols="12"
        class="text-right"
        )
        b-button(
          variant="primary"
          @click="submit"
          )
          | {{ $t('message.submit') }}

</template>

<script>
// eslint-disable-next-line import/extensions
import { onMounted, toRefs } from '@vue/composition-api/dist/vue-composition-api'

export default {
  name: 'Form',

  components: {

  },

  props: ['data'],

  setup(props, { emit }) {
    
    function submit() {
      emit('submit', toRefs(props.data))
    }

    onMounted(async () => {
    })

    return {
      submit,
    }
  }
}
</script>

<style scoped>

</style>

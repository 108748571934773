import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'

export default function useNotifications()
{
  const toast = useToast()
  const showSuccessMessage = (title, timeout = 3000) => {
    toast({
      component: ToastificationContent,
      props: {
        title,
        icon: 'EditIcon',
        variant: 'primary',
      },
    }, { timeout })
  }

  const showErrorMessage = (title, timeout = 3000) => {
    toast({
      component: ToastificationContent,
      props: {
        title,
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }, { timeout })
  }

  return {
    showSuccessMessage,
    showErrorMessage,
  }
}

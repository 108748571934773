<template lang="pug">
div
  Form(:data="location", @submit="update")
</template>

<script>
import { onMounted } from '@vue/composition-api'

import Form from '../shared/Form.vue'
// eslint-disable-next-line import/no-cycle
import useClientEdit from './useClientEdit'

export default {
  components: {
    Form,
  },

  setup() {
    const { show, location, update } = useClientEdit()
    onMounted(show)

    return {
      location,
      update,
    }
  },
}
</script>
